<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="share-from-square"/> Template Prompt Shared
            </CCardHeader>

            <CCardBody>
                <CDataTable
                    :items="sorted_dt_shared_list"
                    :fields="tp_shared_field"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    border
                    items-per-page-select
                    sorter
                    pagination
                    :loading="this.table_loading"
                    hover
                >
                    <template slot="no-items-view" v-if="this.table_loading">
                        <center>
                            <CSpinner
                                style="width:4rem;height:4rem;"
                                color="info"
                            />
                        </center>
                    </template>
                    <template #name="{item}">
                        <td>
                            {{ item.name }}
                        </td>
                    </template>
                    <template #summary="{item}">
                        <td>
                            <CButton @click="getDocumentTemplates(item.id)"
                                    pressed
                                    aria-pressed="true"
                                    variant="ghost"
                                    class="btn-summary"
                                    size="sm"
                                    title="Number of shared document template(s) this user belong."
                                    color="dark" 
                                >
                                    Shared to {{ item.template_count }} document template/s
                            </CButton>
                        </td>
                    </template>
                    
                    <template #Action="{item}">
                        <td>
                            <router-link :to="{ path: `/${$store.getters['getOrganizationName']}/drs/template-prompt-shared/${paramEncoder(item.id)}`, query: {name:`${item.name}`, prompt_id: paramEncoder(item.id)} }">
                                <CButton color="info" shape="pill" size="sm">
                                <font-awesome-icon icon="cog"/> Manage
                                </CButton>
                            </router-link>
                            
                        </td>
                    </template>
                </CDataTable>
            </CCardBody>
        </CCard>
        <CModal  :show.sync=" dt_shared_modal"
        color="primary"
        size="lg"
        :closeOnBackdrop="false">
        <template #header>
            <h5><font-awesome-icon icon="share-from-square"/>  Shared Document Template/s </h5>
            <CButton 
                class="pull-right" 
                color="light" 
                shape="pill" 
                size="sm"
                @click=" dt_shared_modal = false"
            >
                <font-awesome-icon icon="times"/>
            </CButton>
        </template>

        <template #body-wrapper>
            <CCardBody>
                <CDataTable
                    :items="sorted_templates"
                    :fields="dt_field"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    items-per-page-select
                    border
                    sorter
                    pagination
                    :loading="dt_table_loading"
                    size="sm"
                >
                </CDataTable>
            </CCardBody>
        </template>
        <template #footer>
            <hr hide>
        </template>
        </CModal>    
</div>

</template>

<script>
import axios from 'axios';
export default{
    name : 'DocumentTemplateShared',
    data() {
        return {
            table_loading: true,
            tp_shared_list  : [],
            dt_table_loading: true,
            document_templates: [],
            document_templates_fields: [
                {key: 'name', label: 'Name'},
                {key: 'category_name', label: 'Category Name'},
            ],
            dt_shared_modal: false,
        }
    },

    created(){
        this.getTPShared();
        this.$emit('activeTab', 0);
    },

    computed : {
        tp_shared_field: function () {
            let custom_fields= [
                { key: 'name', label: 'Name'},
                { key: 'summary'},
                // { key: 'status' },
            ]
            if(this.$store.getters.can('view-template-prompt-shared')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        },
        dt_field: function () {
            let custom_fields= [
                {key: 'name', label: 'Name'},
                {key: 'category_name', label: 'Category Name'},
            ]
            return custom_fields;
        },
        sorted_dt_shared_list () {
            return this.tp_shared_list
            .filter(item => item.template_id != null && item.template_count != 0 && item.deleted_at === null)
            .sort((a,b) => a.name.localeCompare(b.name));
        },
        sorted_templates : function () {
            return this.document_templates
            .filter(item => item.deleted_at === null)
            .sort((a,b) => a.name.localeCompare(b.name));
        }
    },
    watch : {},
    methods : {

        getTPShared : function () {
            this.$Progress.start()
            axios.get('/drs/template-prompt-shared/list', {validateStatus: ()=>true})
                .then( response => {
                    this.tp_shared_list = response.data.data;
                    this.table_loading = false;
                    this.$Progress.finish()
                });
        },

        getDocumentTemplates : function(prompt_id) {
            this.$Progress.start()
            this.dt_shared_modal = true
            this.dt_table_loading=true;
            let encoded_prompt_id = this.paramEncoder(prompt_id);
            let _data = {
                prompt_id:encoded_prompt_id
            };
            this.$Progress.start()
            this.list_table_loading=true;
            if(this.document_templates.length > 0){
                this.document_templates = [];
            }
          axios.post('/drs/template-prompt-shared/dt-list', _data, {validateStatus: () => true})
            .then(response => {
                if(response.status==200){
                    this.document_templates = response.data.data;
                    this.dt_table_loading = false
                    this.$Progress.finish()
                }
            })
        },
    }
}
</script>

